<template>
    <div class="modal fade game-point-note-modal" id="gamePop1" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border: none;">
                <div class="modal-body">
                    <section class="sub-header-wrapper">
                        <div class="gp-content">
                           <!-- <img class="gp-img" src="@/assets/images/live-casino-popup-img.svg" alt="casino" > -->
                            <!-- <img data-bs-dismiss="modal" class="gp-closeButton" src="@/assets/images/close-button.svg" alt="close-button"> -->
                            <div class="gp-title">PLEASE NOTE</div>
                            <div class="gp-descr">(1 Point = ₹ 100)</div>
                            <div class="gp-welcome">Welcome to your new casino lobby</div>
                            <div class="gp-checkout">Checkout the exciting Live Casino tables, Slots and much more, upto
                                200+ game and counting...</div>
                            <div @click="$emit('agreed')" class="gp-button thm-but ok-agree-btn">OK, I AGREE !</div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'GamesPop1',
}
</script>