const state = {
    siteSettings: null,
    gamesData: null,
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    clickBetValue: null,
    favouriteGames: [],
    globalLoader: false,
    depositAmount: 0,
    withdrawAmount: 0,
    captchaData: null,
    notification: 0,
    languagesData: null,
    sportList: null,
    successMsg: null,
    errorMsg: null,
    underMaintenance: null,
    bonusData: null
};
const getters = {
    siteSettings: state => state?.siteSettings,
    languagesData: state => state?.siteSettings?.languagesList,
    gamesData: state => state?.gamesData,
    bonusData: state => state?.bonusData,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    clickBetActive: state => state?.clickBetValue?.find(item => item.isSelected) ?? false,
    favouriteGames: state => state?.favouriteGames,
    globalLoader: state => state?.globalLoader,
    depositAmount: state => state?.depositAmount,
    withdrawAmount: state => state?.withdrawAmount,
    captchaData: state => state?.captchaData,
    getNotification: state => state?.notification,
    sportList: state => state?.sportList,
    successMsg: state => state?.successMsg,
    errorMsg: state => state?.errorMsg,
    underMaintenance: state => state?.underMaintenance,
};
const actions = {
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setLanguagesData({ commit }, languagesData) {
        commit('setLanguagesData', languagesData);
    },
    setBonusData({ commit }, bonusData) {
        commit('setBonusData', bonusData);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setFavouriteGames({ commit }, favouriteGames) {
        commit('setFavouriteGames', favouriteGames);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    setDepositAmount({ commit }, depositAmount) {
        commit('setDepositAmount', depositAmount);
    },
    setWithdrawAmount({ commit }, withdrawAmount) {
        commit('setWithdrawAmount', withdrawAmount);
    },
    setCaptchaData({ commit }, captchaData) {
        commit('setCaptchaData', captchaData);
    },
    setNotification({ commit }, notification) {
        commit('setNotification', notification);
    },
    setSport({ commit }, sports) {
        commit('setSport', sports);
    },
    showSuccessModal({ commit }, message) {
        commit('showSuccessModal', message);
        setTimeout(() => {
            commit('showSuccessModal', null);
        }, 2000);
    },
    showErrorModal({ commit }, message) {
        commit('showErrorModal', message);
        setTimeout(() => {
            commit('showErrorModal', null);
        }, 2000);
    },
    setUnderMaintenance({ commit }, underMaintenance) {
        commit('setUnderMaintenance', underMaintenance);
    },
};
const mutations = {
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings;
    },
    setLanguagesData(state, languagesData) {
        state.languagesData = languagesData;
    },
    setBonusData(state, bonusData) {
        state.bonusData = bonusData;;
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData;;
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setFavouriteGames(state, favouriteGames) {
        state.favouriteGames = favouriteGames;
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },
    setDepositAmount(state, depositAmount) {
        state.depositAmount = depositAmount
    },
    setWithdrawAmount(state, withdrawAmount){
        state.withdrawAmount = withdrawAmount
    },
    setCaptchaData(state, captchaData) {
        state.captchaData = captchaData;
    },
    setNotification(state) {
        state.notification = state.notification + 1;
    },
    setSport(state, sports) {
        state.sportList = sports;
    },
    showSuccessModal(state, message) {
        state.successMsg = message
    },
    showErrorModal(state, message) {
        state.errorMsg = message
    },
    setUnderMaintenance(state, underMaintenance) {
        state.underMaintenance = underMaintenance;
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};