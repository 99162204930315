<template>
    <div class="modal fade game-play-modal" id="gamesIframe" tabindex="-1" aria-labelledby="gamesModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-fullscreen" style="max-width: 100%;">
            <div v-if="gameData" class="modal-content">
                <div class="modal-header">
                    <div class="game-header-content">
                        <div class="game-bak-close-block">
                            <h5>{{ gameData?.gameName }}</h5>
                        </div>
                    </div>
                    <div class="container-close">
                        <button type="button" class="btn-close" aria-label="Close" @click="$emit('closeGame')">×</button>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="game-iframe-sec">
                        <div v-if="this.gameData" class="iframe-content">
                            <iframe :src="this.gameData.gameUrl" frameborder="0"
                                style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"
                                height="100%" width="100%" allow="autoplay; fullscreen; picture-in-picture"
                                allowfullscreen title="Tr&amp;eacute;ning TEXT"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'IFrameModal',
    props: ['gameData'],
}
</script>