const AuthorizationModule = () => import(/* webpackChunkName: "authorization-module" */ './views/Module.vue');
const LandingPage = () => import(/* webpackChunkName: "landing-page" */ './views/LandingPage.vue');

const AuthorizationRoutes = {
    path: '',
    component: AuthorizationModule,
    children: [
        {
            path: '',
            name: 'LandingPage',
            component: LandingPage
        },
        {
            path: 'login',
            name: 'Login',
            component: LandingPage
        },
        {
            path: 'register',
            name: 'SignUp',
            component: LandingPage
        },
        {
            path: 'forgot-password',
            name: 'ForgotPassword',
            component: LandingPage
        },
    ]
};

export default AuthorizationRoutes;