const ChatModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const ChatComponent = () => import(/* webpackChunkName: "games" */ './views/ChatComponent.vue');

const ChatRoutes = {
    path: '/',
    component: ChatModule,
    children: [
        {
            path: 'chats',
            name: 'Chats',
            component: ChatComponent
        }
    ],
}

export default ChatRoutes;