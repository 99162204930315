
import AuthorizationRoutes from "@/modules/authorization";
import BetsRoutes from "@/modules/bets";
import BonusRoutes from "@/modules/bonus";
import FavouritesRoutes from "@/modules/favourites";
import RaceRoutes from "@/modules/race";
import SportsRoutes from "@/modules/sports";
import VirtualSportRoutes from "@/modules/virtual-sport";
import MyMarketRoutes from "@/modules/my-market";
import NotificationsRoutes from "@/modules/notification";
import ReferEarnRoutes from "@/modules/refer-earn";
import GamesRoutes from "@/modules/games";
import MatkaRoutes from "@/modules/matka";
import WalletRoutes from "@/modules/wallet";
import ChatRoutes from "@/modules/chats";
// import ProfileRoutes from "@/modules/profile";
// import StaticRoutes from "@/modules/static";
// import WalletRoutes from "@/modules/wallet";
import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("../views/Home.vue");
const PageNotFound = () => import("@/shared/components/no-data/PageNotFound.vue");

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  FavouritesRoutes,
  BonusRoutes,
  SportsRoutes,
  RaceRoutes,
  VirtualSportRoutes,
  MyMarketRoutes,
  NotificationsRoutes,
  ReferEarnRoutes,
  BetsRoutes,
  GamesRoutes,
  MatkaRoutes,
  AuthorizationRoutes,
  // StaticRoutes,
  // ProfileRoutes,
  WalletRoutes,
  ChatRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound
  }
];


export default createRouter({
  history: createWebHistory(),
  routes
});

