<template>
	<div class="click-splash-screen" v-if="!isSiteStatusCalled">
		<div class="click-logo-sec shiny-cta">
			<img :src="$siteSettings.data?.aws_url + $siteSettings.data?.storage_path.domain_image + $siteSettings.data?.logo" alt="logo" loading="lazy" />                     
		</div>
	</div>
	<router-view v-if="($route.name == 'virtual-sport' || $route.name == 'sports-book' || $route.name == 'PageNotFound' || lpRoutes.includes($route.path)) && isSiteStatusCalled"/>
	<template v-else-if="isSiteStatusCalled">

		<Header v-if="!WalletRoutes.includes($route.name)" />

		<SportSelection v-if="checkRoutes() && !WalletRoutes.includes($route.name)" />

		<router-view />

		<MobileFooter v-if="$route.name != 'Chats'"></MobileFooter>

		<div v-if="successMsg" style="z-index: 9999999999999999;" id="successfullyModal" class="show successfully-wrapper">
			<SuccessModal :message="successMsg" />
		</div>

		<div v-if="errorMsg" style="z-index: 9999999999999999;" id="wrongtoaster" class="show successfully-wrapper">
			<ErrorModal :message="errorMsg" />
		</div>		
	</template>
	<ChangeAndWithdrawPasswordModal/>
	<div class="loader_container" v-if="globalLoader">
        <div class="box">
            <div class="loader">
                
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import { defineAsyncComponent } from 'vue';
import socketService from './socket/sport-socket-service';

export default {
	name: "App",
	components: {
		Header: defineAsyncComponent(() =>
			import('./shared/components/header/Header.vue')
		),
		SportSelection: defineAsyncComponent(() =>
			import('./shared/components/sport-selection/SportSelection.vue')
		),
		MobileFooter: defineAsyncComponent(() =>
			import('./shared/components/footer/MobileFooter.vue')
		),
		SuccessModal: defineAsyncComponent(() =>
			import('@/shared/components/modal/SuccessModal.vue')
		),
		ErrorModal: defineAsyncComponent(() =>
			import('@/shared/components/modal/ErrorModal.vue')
		),
		ChangeAndWithdrawPasswordModal: defineAsyncComponent(() =>
			import('@/shared/components/modal/ChangeAndWithdrawPasswordModal.vue')
		)
	},
	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			refreshInit: false,
			scY: 0,
			showGoToTop: false,
			lpRoutes: ['/login', '/register', '/forgot-password', '/'],
			WalletRoutes:[
				'Wallet',
				'WalletDeposit',
				'WalletWithdraw',
				'WalletHistory',
				'TransactionDetails',
				'DepositPaymentMethod',
				'WithdrawDetails',
				'WithdrawAccountDetails',
				'Chats'
			],
			isSiteStatusCalled: false
		}
	},
	computed: {
		...mapGetters(['successMsg', 'errorMsg', 'siteVersion', 'siteSettings', 'underMaintenance', 'globalLoader'])
	},
	mounted() {
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
		setHeaders();

		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		window.addEventListener('scroll', this.onScroll);
		this.$errorToast(null);
		this.$successToast(null);
		this.$store.dispatch('setGlobalLoader', false);
		this.getSiteVersion();
		this.socketAllEvents();
	},
	created() {
	},
	methods: {
		async getSiteVersion() {
			this.$apiCall('get', 'GET_SITE_VERSION', null, 'user', { domain: window.location.hostname }).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {
						let resData = response.data.data;
						let currentVersion = resData.version;
						let userSystemVersion = this.siteVersion || 0;
						if (!userSystemVersion || currentVersion != userSystemVersion || !this.siteSettings) {
							await this.getSiteSettingsServiceCall();
						} else {
							this.setSiteSttings(this.siteSettings);
						}

						if (resData.maintenance) {
							const { from_date, to_date } = resData.maintenance;
							const fromDateTime = new Date(from_date).getTime();
							const toDateTime = new Date(to_date).getTime();
							const currentDateTime = Date.now();

							const isInMaintenanceWindow = fromDateTime <= currentDateTime && currentDateTime <= toDateTime;

							if (fromDateTime && toDateTime && isInMaintenanceWindow) {
								this.$store.dispatch('setUnderMaintenance', resData.maintenance);
								this.$router.push({ name: 'Maintenance' });
							}
						}
					}
					this.isSiteStatusCalled = true;
				}
			}).catch(error => {
				console.log(error);
				
				if (error) {
					this.$errorToast(error[0]);
				}
			});
		},
		async getSiteSettingsServiceCall() {
			await this.$apiCall('get', 'GET_SITE_SETTINGS', null, 'user', { domain: window.location.hostname }).then(response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {

						let resData = response?.data.data;
						(resData.siteSetting.is_landing_page && !this.$auth.status) && this.$router.push({name: 'LandingPage'})
						resData.siteSetting.announcement = JSON.parse(resData.siteSetting.announcement)
						this.$store.dispatch('setSettings', resData.siteSetting);
						this.$store.dispatch('setGamesData', resData.casinoGames);
						this.$store.dispatch('setBonusData', resData.bonus);
						this.setSiteSttings(resData.siteSetting);
						this.$store.dispatch('setSiteVersion', resData.siteSetting.version);
					}
				}
			}).catch(error => {				
				if (error) {
					this.$errorToast(error[0]);
				}
			});
		},
		setSiteSttings(siteData) {
			if (siteData.languagesList) {
				this.$setCurrentLangData(siteData.languagesList, siteData.language_code, true, siteData.aws_url)
			}

			this.$store.dispatch('setSettings', siteData);
			this.setManifest(siteData);

			if (siteData.front_css != null && siteData.front_css != '') {// Create a style element
				const styleElement = document.createElement('style');
				styleElement.type = 'text/css';
				styleElement.appendChild(document.createTextNode(siteData.front_css));
				document.head.insertAdjacentElement('beforeend', styleElement);
			}

			// Create FAVICON
			const favElement = document.createElement('link');
			favElement.rel = 'icon';
			favElement.href = siteData.aws_url + siteData.storage_path.domain_image + siteData.fav_logo;
			document.head.insertAdjacentElement('beforeend', favElement);

			const title = document.getElementById("title");
			title.text = siteData.domain_name;
			if (this.$auth.status) {
				this.getWalletBalance();
				this.getFavourites();
			}
			this.isSiteStatusCalled = true;

		},
		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}

			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);

			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);

			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
		getFavourites() {
			let data = {
				"game_type": 1
			}
			this.$apiCall('post', 'GET_FAVOURITE_LIST', data).then(response => {
				if (response) {
					if (response.status == 200) {
						if (response?.data?.status != 0) {
							var allData = response.data.events;
							var ids = [];
							for (var i = 0; i < allData.length; i++) {
								ids.push(allData[i])
							}
							this.$store.dispatch('setFavouriteGames', ids)
						}
					}
				}
			}).catch(error => {
				if (error) {
				}
			});
		},
		getWalletBalance() {
			this.$apiCall('post', 'WALLET_BALANCE').then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.expl = response.data.ex;
					user.bets_count = response.data.bc;
					this.$store.dispatch('setUser', user);
					if ((user.exp - this.$dateTime.currentTimestamp()) < 10) {
						this.refreshAuthToken();
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 4000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
			});
		},
		refreshAuthToken(refresh = false) {
			this.$apiCall('post', 'AUTH_REFRESH').then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
				if (refresh) {
					router.go();
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
				this.refreshInit = false;
			});
		},
		goToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
		onScroll() {
			if (this.scTimer) return;
			this.scTimer = setTimeout(() => {
				this.scY = window.scrollY;
				this.showGoToTop = this.scY > 80;
				clearTimeout(this.scTimer);
				this.scTimer = 0;
			}, 100);
		},
		checkRoutes() {
			let routeName = this.$route.name;
			let notAllowedROute = [
				'bonus',
				'racing-carousel',
				'racing-category',
				'racing-list-details',
				'notifications',
				'refer-earn'
			];
			return !notAllowedROute.includes(routeName);
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		socketAllEvents() {
			if (this.$auth.status) {
				socketService.emit("sub", 'DepositRequest-' + this.$auth.user.userid);

				socketService.on("DepositRequest", (data) => {
					this.$successToast(data.msg)
				});
			}
		},
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	}
};
</script>
