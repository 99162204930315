
const WalletModule = () => import(/* webpackChunkName: "wallet-module" */ './views/Module.vue');
const Wallet = () => import(/* webpackChunkName: "wallet-gateway" */ './views/Wallet.vue');
const WalletDeposit = () => import(/* webpackChunkName: "wallet-gateway" */ './views/WalletDeposit.vue');
const WalletWithdraw = () => import(/* webpackChunkName: "wallet-gateway" */ './views/WalletWithdraw.vue');
const WalletHistory = () => import(/* webpackChunkName: "wallet-gateway" */ './views/WalletHistory.vue');
const TransactionDetails = () => import(/* webpackChunkName: "wallet-gateway" */ './views/TransactionDetails.vue');
const DepositPaymentMethod = () => import(/* webpackChunkName: "wallet-gateway" */ './views/DepositPaymentMethod.vue');
const WithdrawDetails = () => import(/* webpackChunkName: "wallet-gateway" */ './views/WithdrawDetails.vue');
const WithdrawAccountDetails = () => import(/* webpackChunkName: "wallet-gateway" */ './views/WithdrawAccountDetails.vue');


const WalletRoutes = {
    path: '/',
    component: WalletModule,
    children: [
      {
        path: 'wallet',
        name: 'Wallet',
        component: Wallet,
        meta: { title: 'Wallet' }
      },
      {
        path: 'wallet-deposit',
        name: 'WalletDeposit',
        component: WalletDeposit,
        meta: { title: 'Wallet Deposit' }
      },
      {
        path: 'wallet-withdraw',
        name: 'WalletWithdraw',
        component: WalletWithdraw,
        meta: { title: 'Wallet Withdraw' }
      },
      {
        path: 'wallet-history',
        name: 'WalletHistory',
        component: WalletHistory,
        meta: { title: 'Wallet History' }
      },
      {
        path: 'transaction-details/:id',
        name: 'TransactionDetails',
        component: TransactionDetails,
        meta: { title: 'Transaction Details' }
      },
      {
        path: 'deposit-payment',
        name: 'DepositPaymentMethod',
        component: DepositPaymentMethod,
        meta: { title: 'Deposit Payment' }
      },
      {
        path: 'withdraw-details',
        name: 'WithdrawDetails',
        component: WithdrawDetails,
        meta: { title: 'Wtihdraw Details' }
      },
      {
        path: 'add-new-account/:id',
        name: 'WithdrawAccountDetails',
        component: WithdrawAccountDetails,
        meta: { title: 'Add new Account' }
      },
    ],
}
export default WalletRoutes;