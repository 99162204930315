import { Modal } from "bootstrap";

export const successToast = (message) => {
    window.store.dispatch('showSuccessModal', message);
};

export const errorToast = (message) => {
    window.store.dispatch('showErrorModal', message);
};

export const helper = {
    showLogin() {
        let login = new Modal('#loginModal');
        login.show();
    },
    openModal(id) {
        setTimeout(() => {
            let modal = new Modal(id);
            modal.show();
        }, 100)
    },
    closeModal(id) {
        setTimeout(() => {
            let closeModal = document.getElementById(id)
            let modal = Modal.getInstance(closeModal);
            if(modal) {
                modal.hide();
            }
        }, 100)
    },
    kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    },
    formatNumber(value){
        if (value !== null) {
            return parseFloat(value).toFixed(2);
        } else {
            return 0;
        }
    },
    downloadExcel(data, fileName){
        let XLSX = require('xlsx');
        
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, fileName);
    },
    downloadPdf(data, fileName) {
        const { jsPDF } = require('jspdf');
        require('jspdf-autotable');

        const doc = new jsPDF();
        doc.autoTable({
            head: [Object.keys(data[0])],
            body: data.map((obj) => Object.values(obj)),
        });
        doc.save(fileName);
    },
    notNull(item){
        return item !== ('' || null || undefined)
    },
    compareLength: (len, prop) => {
        return prop.length == len
    },
    testRegex(regex, data) {
        return regex.test(data);
    },
    isMobile() {
        let screenSize = window.innerWidth;
        let mobileView = 991;
        return screenSize<=mobileView
    },
    //----- sanitization helpers
    sanitizeDetails(field, prop, sliceLength) {
        const userId = /[^a-zA-Z0-9_]/g
        const password = /[^a-zA-Z0-9]/g
        const referCode = /[^0-9]/g
        return prop.replace([field] , '').slice(0, sliceLength);
    },
    sanitizeNumber(prop, maxValue) {
        if (prop != '') {
            let stringValue = prop.toString();
            stringValue = stringValue.replace(/[^0-9]/g, '').replace(/[+-]/g, '');
            let sanitizedNumber= '';
            maxValue == 999999 ? sanitizedNumber = stringValue.slice(0,6) ?? '' : sanitizedNumber = stringValue == '' ? '' : Math.max(0, Math.min(maxValue, stringValue)).toString()
            return sanitizedNumber
        }
        return prop;
    },
    //----- sanitization helpers
    positive(val) {
        if (val < 0) {
          return val * -1;
        }
        return val;
    },
    addCommas(val) {
        var val = parseFloat(val).toFixed(2);
        val += '';
        var x = val.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        var total = x1 + x2;
        return total;
    }
}