import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import VueCookies from 'vue3-cookies';
import "./assets/css/bootstrap.min.css";
// import "./assets/css/chat.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/fontawesome/css/all.css";
import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import globalProperties from './globalServices';
import VueAwesomePaginate from "vue-awesome-paginate";
import Clarity from '@microsoft/clarity';
Clarity.init(process.env.VUE_APP_CLARITY_PROJECT_ID);

const app = createApp(App);
app.use(globalProperties);
app.use(router);
app.use(store);
app.use(VueCookies);
app.use(VueAwesomePaginate);
app.mount("#app");