import axios from "axios";
import { reactive } from "vue";

const langState = reactive({
    langData: {},
});

let awsUrl = null;



const setCurrentLangData = async (langList, langCode = null, init = true, langAws = awsUrl) => {
	try {
		if (!awsUrl) {
			awsUrl = langAws;
		}

		langCode = langCode ?? (localStorage.getItem('selectedLanguageCode') || 'en');
		
		let selectedLang = langList.find(lang => lang.lang_code === langCode);

		if (!selectedLang) {
			langState.langData = {};
			localStorage.removeItem('selectedLanguageCode');
			return init ? true : Promise.resolve('success');
		}
		
		let response = await callForLoadTranslation(selectedLang.lang_url, awsUrl);
		langState.langData = response.data;
		localStorage.setItem('selectedLanguageCode', langCode);
		
		return init ? true : Promise.resolve('success');

	} catch (e) {
		console.log(e)
		let checkInit = init;
		return checkInit ? false : Promise.reject('Failed to change language. Try different language.');
	}

};

const translatedData = (key, defaultVal) => {
	if (langState.langData?.[key]) {
		return langState.langData?.[key];
	} else {
		return defaultVal;
	}
};


const callForLoadTranslation = async (translationUrlData, awsUrl) => {
	const response = await axios.get(awsUrl + '/' + translationUrlData);
	return response;
}


export {setCurrentLangData, translatedData};